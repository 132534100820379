import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { rootReducer } from './store';
import { Theme, MuiThemeProvider, CssBaseline } from '@material-ui/core';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { loadState, saveState } from './store/localstorage';

import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

const theme: Theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

const persistedState = loadState();

const middleWare = process.env.NODE_ENV !== 'production' ? [reduxImmutableStateInvariant()] : [];
const store = createStore(rootReducer, persistedState, composeWithDevTools(applyMiddleware(...middleWare)));

store.subscribe(() => saveState(store.getState()));

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
