import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Grid, TextField } from '@material-ui/core';
import { RootState } from '../store';
import { AttributeType, DiceType, DiceValue } from '../store/common';
import { AttributeSelect, DieSelect } from '.';

interface ComponentProps {
  diceValue: DiceValue,
  onChange: (value: DiceValue) => void,
}

const mapStateToProps = (state: RootState) => ({
  dice: state.options.dice,
  attributes: state.options.attributes,
});

const mapDispatchToProps = {
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ComponentProps;

const EditDiceValue: React.FunctionComponent<Props> = ({ diceValue, onChange, dice, attributes }) => {
  if (diceValue.type === DiceType.modifier) {
    return (
      <Grid item>
        <TextField
          required
          label="Modifier"
          type="number"
          value={diceValue.modifier}
          onChange={(event) => onChange({ ...diceValue, modifier: Number(event.target.value) })}
          style={{ width: 120 }}
        />
      </Grid>
    );
  }

  if (diceValue.type === DiceType.baseAttack) {
    return (
      <Grid item style={{ marginBottom: 4 }}>Base Attack</Grid>
    );
  }

  if (diceValue.type === DiceType.attribute) {
    return (
      <Grid item>
        <AttributeSelect value={diceValue.attribute} onChange={(value) => onChange({ ...diceValue, attribute: value as AttributeType })} />
      </Grid>
    );
  }

  if (diceValue.type === DiceType.roll) {
    return (
      <>
        <Grid item>
          <TextField
            required
            label="Count"
            type="number"
            value={diceValue.count}
            onChange={(event) => onChange({ ...diceValue, count: Number(event.target.value) })}
            style={{ width: 70 }}
          />
        </Grid>
        <Grid item>
          <DieSelect value={diceValue.die} onChange={(die) => onChange({ ...diceValue, die })} />
        </Grid>
      </>
    );
  }

    return (
      <span>Unknown</span>
    )
}

export default connector(EditDiceValue);
