import React from 'react';
import { AppBar, Grid, Paper, Tab, Tabs, useTheme } from '@material-ui/core';
import {
  AccountCircle as AccountCircleIcon,
  Casino as CasinoIcon,
  History as HistoryIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';
import { connect, ConnectedProps } from 'react-redux';
import { DiceSetCard, EditOptions, Header, EditQuickRoll, SelectedCharacter, TabPanel, HistoryCard } from './components';
import CharacterCard from './components/CharacterCard';
import { RootState } from './store';
import { useStyles } from './styles';
import { isMobile } from 'react-device-detect';

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
};

const mapStateToProps = (state: RootState) => ({
  diceSets: state.diceSets.list,
  characters: state.characters.list,
  selected: state.characters.selected,
  history: state.history.list,
})

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const App: React.FunctionComponent<Props> = ({ diceSets, characters, selected, history, }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [currentTab, setCurrentTab] = React.useState<number>(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <Header />
      <section className={classes.main}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12}>
            <Grid container spacing={2} justify="center">
              {selected && (
                <Grid item>
                  <SelectedCharacter />
                </Grid>
              )}
              <Grid item>
                <EditQuickRoll />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.tabPaper}>
              <AppBar position="static" color="default">
                <Tabs
                  value={currentTab}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="secondary"
                  centered
                >
                  <Tab icon={<CasinoIcon />} label={!isMobile && "Dice Sets"} {...a11yProps(0)} />
                  <Tab icon={<AccountCircleIcon />} label={!isMobile && "Characters"} {...a11yProps(1)} />
                  <Tab icon={<HistoryIcon />} label={!isMobile && "History"} {...a11yProps(2)} />
                  <Tab icon={<SettingsIcon />} label={!isMobile && "Options"} {...a11yProps(3)} />
                </Tabs>
              </AppBar>
              <TabPanel value={currentTab} index={0} dir={theme.direction}>
                <Grid container spacing={2} justify="center">
                  {diceSets.map((item) => (
                    <Grid item key={item.id}>
                      <DiceSetCard diceSet={item} />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
              <TabPanel value={currentTab} index={1} dir={theme.direction}>
                <Grid container spacing={2} justify="center">
                  {characters.map((item) => (
                    <Grid item key={item.id}>
                      <CharacterCard character={item} />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
              <TabPanel value={currentTab} index={2} dir={theme.direction}>
                <Grid container spacing={2} justify="center">
                  {history.map((item) => (
                    <Grid item key={item.id}>
                      <HistoryCard rollResult={item} />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
              <TabPanel value={currentTab} index={3} dir={theme.direction}>
                <EditOptions />
              </TabPanel>
            </Paper>
          </Grid>
        </Grid>
      </section>
    </>
  );
}

export default connector(App);
