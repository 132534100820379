import React from 'react';
import { Card, CardActions, CardContent, IconButton, Tooltip, Typography, Grid, TextField } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { AttributeSelect, DieSelect } from '.';
import { RootState } from '../store';
import { RollResult, rollDice, DiceType, AttributeType, DiceSet, newDiceSet } from '../store/common';
import { Casino, Save } from '@material-ui/icons';
import { useStyles } from '../styles';
import clsx from 'clsx';
import { QuickRoll } from '../store/quickRoll/types';
import { setQuickRollAction } from '../store/quickRoll/actions';
import RollResultDisplay from './RollResultDisplay';
import { Guid } from 'guid-typescript';
import { addHistoryAction } from '../store/history/actions';

const mapStateToProps = (state: RootState) => ({
  quickRoll: state.quickRoll,
  selected: state.characters.selected,
  characters: state.characters.list,
  options: state.options
})

const mapDispatchToProps = {
  setQuickRoll: (quickRoll: QuickRoll) => setQuickRollAction(quickRoll),
  addHistory: (rollResult: RollResult) => addHistoryAction(rollResult),
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const EditQuickRoll: React.FunctionComponent<Props> = ({ quickRoll, selected, characters, options, setQuickRoll, addHistory }) => {
  const classes = useStyles();
  const [rollResult, setRollResult] = React.useState<RollResult | undefined>();

  const onRollClick = () => {
    const diceSet: DiceSet = newDiceSet('Quick Roll');

    diceSet.dice.push({
      id: Guid.raw(),
      diceValue: {
        type: DiceType.roll,
        count: quickRoll.count,
        die: quickRoll.die,
      },
    });

    if (quickRoll.modifier !== 0) {
      diceSet.dice.push({
        id: Guid.raw(),
        diceValue: {
          type: DiceType.modifier,
          modifier: quickRoll.modifier,
        },
      })
    };

    if (quickRoll.attribute) {
      diceSet.dice.push({
        id: Guid.raw(),
        diceValue: {
          type: DiceType.attribute,
          attribute: quickRoll.attribute as AttributeType,
        },
      })
    };

    const character = characters.find((item) => item.id === selected);
    const value = rollDice(diceSet, options, character);

    addHistory(value);
    setRollResult(value);
  }

  const clearRoll = () => {
    setRollResult(undefined);
  };

  React.useEffect(() => {
    clearRoll();
  }, [quickRoll]);

  return (
    <Card className={clsx(classes.card, classes.quickRoll)}>
      <CardContent>
        <Typography className={classes.title} gutterBottom>
          Quick Roll
        </Typography>
        <Grid container spacing={2} style={{ paddingBottom: 0 }}>
          <Grid item>
            <TextField
              label="Count"
              type="number"
              value={quickRoll.count}
              onChange={(event) => setQuickRoll({ ...quickRoll, count: Number(event.target.value) })}
              style={{ minWidth: 64, width: 64 }}
            />
          </Grid>
          <Grid item>
            <DieSelect value={quickRoll.die} onChange={(value: number) => setQuickRoll({ ...quickRoll, die: value })} />
          </Grid>
          <Grid item>
            <TextField
              label="Modifier"
              type="number"
              value={quickRoll.modifier}
              onChange={(event) => setQuickRoll({ ...quickRoll, modifier: Number(event.target.value) })}
              style={{ minWidth: 64, width: 64 }}
            />
          </Grid>
          <Grid item>
            <AttributeSelect value={quickRoll.attribute} onChange={(value: string) => setQuickRoll({ ...quickRoll, attribute: value })} size="small" />
          </Grid>
        </Grid>
        {rollResult && <RollResultDisplay rollResult={rollResult} />}
      </CardContent>
      <CardActions style={{ paddingTop: 0 }}>
        <Grid container justify="space-evenly">
          <Grid item>
            <Tooltip title="Roll" arrow>
              <IconButton onClick={onRollClick}><Casino /></IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Save" arrow>
              <IconButton onClick={onRollClick}><Save /></IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}

export default connector(EditQuickRoll);
