import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { FickleAttack } from '../store/common';

interface Props {
  value?: FickleAttack
  onChange?: (value: FickleAttack) => void
}

const FickleAttackSelect: React.FunctionComponent<Props> = ({ value, onChange }) => (
  <TextField
    fullWidth
    select
    label="Fickle Attack"
    value={value || 0}
    onChange={(event) => onChange && onChange(Number(event.target.value) as FickleAttack)}
    style={{ minWidth: 120 }}
  >
    <MenuItem value={0}>None</MenuItem>
    <MenuItem value={1}>1's only</MenuItem>
    <MenuItem value={2}>1's and 2's</MenuItem>
    <MenuItem value={3}>1's, 2's and 3's</MenuItem>
  </TextField>
);

export default FickleAttackSelect;
