import { RollResult } from '../common';

export const RESET_HISTORY = 'RESET_HISTORY';
export const ADD_HISTORY = 'ADD_HISTORY';

export interface History {
  max: number
  list: RollResult[]
};

interface ResetHistoryAction {
  type: typeof RESET_HISTORY
}

interface AddHistoryAction {
  type: typeof ADD_HISTORY
  rollResult: RollResult
}

export type HistoryActionTypes = 
  ResetHistoryAction |
  AddHistoryAction;
