import { InputAdornment, TextField } from '@material-ui/core';
import React from 'react';
import { modifier } from '../store/common';
import { useStyles } from '../styles';

interface Props {
  label: string
  value: number
  onChange: (value: number) => void
}

const AttributeField: React.FunctionComponent<Props> = ({ label, value, onChange }) => {
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      required
      type="number"
      label={label}
      value={value}
      InputProps={{
        endAdornment: <InputAdornment position="end" className={classes.primary}>{modifier(value)}</InputAdornment>
      }}
      onChange={(event) => onChange(Number(event.target.value))}
    />
  );
};

export default AttributeField;
