import { Character } from '../common';
import {
  CharactersActionTypes,
  RESET_CHARACTERS,
  SELECT_CHARACTER,
  ADD_CHARACTER,
  UPDATE_CHARACTER,
  DELETE_CHARACTER,
  CLEAR_CHARACTERS,
} from './types';

export function resetCharactersAction(): CharactersActionTypes {
  return {
    type: RESET_CHARACTERS,
  };
}

export function selectCharacterAction( id: string ): CharactersActionTypes {
  return {
    type: SELECT_CHARACTER,
    id
  }
}

export function addCharacterAction( name: string ): CharactersActionTypes {
  return {
    type: ADD_CHARACTER,
    name,
  };
}

export function updateCharacterAction( character: Character ): CharactersActionTypes {
  return {
    type: UPDATE_CHARACTER,
    character,
  };
}

export function deleteCharacterAction( id: string ): CharactersActionTypes {
  return {
    type: DELETE_CHARACTER,
    id,
  };
}

export function clearCharactersAction(): CharactersActionTypes {
  return {
    type: CLEAR_CHARACTERS,
  };
}
