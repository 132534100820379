import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import { RootState } from '../store';
import { DamageTypes, DiceType, RollResult, Vulnerability } from '../store/common';
import clsx from 'clsx';

interface ComponentProps {
  rollResult: RollResult
}

const mapStateToProps = (state: RootState) => ({
  allowNegativeRolls: state.options.allowNegativeRolls,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ComponentProps;

const RollResultDisplay: React.FunctionComponent<Props> = ({ rollResult, allowNegativeRolls }) => {
  const classes = useStyles();
  const [critical, setCritical] = React.useState<boolean>(false);
  const [total, setTotal] = React.useState<number>(0);
  const [damageTypes] = React.useState(new Map<string, number>());
  const [vulnerabilities, setVulnerabilities] = React.useState<Array<Vulnerability>>([]);
  const { diceResults, diceSet } = rollResult;
  const { multiplier } = diceSet;

  const getVulnerability = (damageType: string) => {
    const previous = vulnerabilities.find((item) => item.key === damageType);

    return previous?.value ?? 1;
  }

  const toggleVulnerability = (damageType: string) => {
    const previous = vulnerabilities.find((item) => item.key === damageType);

    if (previous) {
      setVulnerabilities(vulnerabilities.map((item: Vulnerability) => item.key === damageType ? { key: item.key, value: (item.value + 1) % 3 } as Vulnerability : item))
    } else {
      setVulnerabilities([...vulnerabilities, { key: damageType as DamageTypes, value: 2 }]);
    }
  }

  React.useEffect(() => {
    setVulnerabilities(rollResult.vulnerabilities);
  }, [rollResult.vulnerabilities]);

  React.useEffect(() => {
    function updateMap(map: Map<string, number>, key: string | undefined, value: number) {
      const previous = map.get(key ?? '');
      map.set(key ?? '', value + (previous ?? 0));
    };

    let value = 0;
    damageTypes.clear();

    for (const diceResult of diceResults) {
      const vulnerability = vulnerabilities.find((item) => item.key === diceResult.name);

      if (diceResult.diceValue.type === DiceType.roll) {
        const dmg = diceResult.value * (critical ? multiplier : 1) * (vulnerability?.value ?? 1);
        value += dmg;

        updateMap(damageTypes, diceResult.name, dmg);
      } else {
        const dmg = diceResult.value * (vulnerability?.value ?? 1);

        value += dmg;
        updateMap(damageTypes, diceResult.name, dmg);
      }
    }

    if (!allowNegativeRolls && value <=0) value = 1;

    setTotal(value);
  }, [diceResults, vulnerabilities, critical, multiplier, allowNegativeRolls, damageTypes]);

  if (diceResults.length < 1) {
    return (<></>);
  }

  const damages = Array.from(damageTypes || []).filter(([key]) => key !== '').map(([key, value]) => ({ key, value }));

  return (
    <>
      <div className={clsx(classes.roll, critical && classes.critical)} onClick={() => setCritical(!critical)}>
        {total}
      </div>
      <div>
        {damages.map(({ key, value }) => (
          <span
            key={key}
            className={clsx(classes.damageType, getVulnerability(key) === 0 && classes.immune, getVulnerability(key) === 2 && classes.vulnerable)}
            onClick={() => toggleVulnerability(key)}
          >
            {`${key}: ${value}`}
          </span>
        ))}
      </div>
      <div>
        <Typography color="textSecondary" variant="caption">
          {diceResults.map((diceResult, index) =>(
            <span key={diceResult.id+index.toString()} className={classes.rollResult} style={{ color: diceResult.color }}>
              {diceResult.label ? `${diceResult.label}: ${diceResult.value}` : diceResult.value}
            </span>
          ))}
        </Typography>
        {(critical && multiplier > 1) && (
          <Typography color="textSecondary" variant="caption" style={{ marginLeft: 8 }}>
            <span>{`x${multiplier}`}</span>
          </Typography>
        )}
      </div>
    </>
  );
};

export default connector(RollResultDisplay);
