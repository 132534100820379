import { FickleAttack } from '../common';
import {
  OptionsActionTypes,
  RESET_OPTIONS,
  SET_ALLOW_NEGATIVE_ROLLS,
  SET_FICKLE_ATTACK,
} from './types';

export function resetOptionsAction(): OptionsActionTypes {
  return {
    type: RESET_OPTIONS,
  };
}

export function setAlowNegativeRollsAction(value: boolean): OptionsActionTypes {
  return {
    type: SET_ALLOW_NEGATIVE_ROLLS,
    value,
  };
}

export function setFickleAttackAction(value: FickleAttack): OptionsActionTypes {
  return {
    type: SET_FICKLE_ATTACK,
    value,
  }
}
