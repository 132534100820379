import { RollResult } from '../common';
import {
  HistoryActionTypes,
  RESET_HISTORY,
  ADD_HISTORY,
} from './types';

export function resetHistoryAction(): HistoryActionTypes {
  return {
    type: RESET_HISTORY,
  };
}

export function addHistoryAction(rollResult: RollResult): HistoryActionTypes {
  return {
    type: ADD_HISTORY,
    rollResult,
  };
}
