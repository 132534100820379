import { Attribute, DamageType, Die, FickleAttack } from '../common';

export const RESET_OPTIONS = 'RESET_OPTIONS';
export const SET_ALLOW_NEGATIVE_ROLLS = 'SET_ALLOW_NEGATIVE_ROLLS';
export const SET_FICKLE_ATTACK = 'SET_FICKLE_ATTACK';

export interface Options {
  version?: string
  dice: Die[]
  attributes: Attribute[]
  damageTypes: DamageType[]
  allowNegativeRolls: boolean
  fickleAttack: FickleAttack
};

interface ResetOptionsAction {
  type: typeof RESET_OPTIONS
}

interface SetAllowNegativeRollsAction {
  type: typeof SET_ALLOW_NEGATIVE_ROLLS
  value: boolean
}

interface SetFickleAttackAction {
  type: typeof SET_FICKLE_ATTACK
  value: FickleAttack
}

export type OptionsActionTypes = 
  ResetOptionsAction |
  SetAllowNegativeRollsAction |
  SetFickleAttackAction;
