import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { RootState } from '../store';
import { connect, ConnectedProps } from 'react-redux';

interface ComponentProps {
  value?: string
  includeBAB?: boolean
  size?: 'small' | 'standard'
  onChange?: (value: string) => void
}

const mapStateToProps = (state: RootState) => ({
  attributes: state.options.attributes,
})

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ComponentProps;

const AttributeSelect: React.FunctionComponent<Props> = ({ attributes, value, includeBAB, size, onChange }) => (
  <TextField
    fullWidth
    select
    label={size === 'small' ? "Attr" : "Attribute"}
    value={value}
    onChange={(event) => onChange && onChange(event.target.value)}
    style={{ minWidth: 70 }}
  >
    {attributes.filter((value) => value.value !== 'BAB' || includeBAB).map((item) => (
      <MenuItem key={item.value} value={item.value}>{size === 'small' ? item.value || 'None' : item.label}</MenuItem>
    ))}
  </TextField>
);

export default connector(AttributeSelect);
