import React from 'react';
import { FormControlLabel, Checkbox, Grid } from '@material-ui/core';
import { RootState } from '../store';
import { connect, ConnectedProps } from 'react-redux';
import { setAlowNegativeRollsAction, setFickleAttackAction } from '../store/options/actions';
import { FickleAttack } from '../store/common';
import FickleAttackSelect from './FickleAttackSelect';

const mapStateToProps = (state: RootState) => ({
  allowNegativeRolls: state.options.allowNegativeRolls,
  fickleAttack: state.options.fickleAttack,
});

const mapDispatchToProps = {
  setAllowNegativeRolls: (value: boolean) => setAlowNegativeRollsAction(value),
  setFickleAttack: (value: FickleAttack) => setFickleAttackAction(value),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const AttributeSelect: React.FunctionComponent<Props> = ({ allowNegativeRolls, fickleAttack, setAllowNegativeRolls, setFickleAttack }) => (
  <Grid container spacing={2}>
    <Grid item>
      <FormControlLabel
        control={<Checkbox checked={allowNegativeRolls} onChange={(event, value) => setAllowNegativeRolls(value)} />}
        label="Allow Negative Rolls"
      />
    </Grid>
    <Grid item>
      <FickleAttackSelect value={fickleAttack} onChange={(value: FickleAttack) => setFickleAttack(value)} />
    </Grid>
  </Grid>
);

export default connector(AttributeSelect);
