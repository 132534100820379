import {
  History,
  RESET_HISTORY,
  ADD_HISTORY,
  HistoryActionTypes,
} from './types';

const initialState: History = {
  max: 20,
  list: [],
};

export function historyReducer(state = initialState, action: HistoryActionTypes): History {
  switch (action.type) {
    case RESET_HISTORY:
      return initialState;

    case ADD_HISTORY:
      return {
        ...state,
        list: [ ...(state.list.length >= state.max ? state.list.slice(1) : state.list), action.rollResult],
      };

    default:
      return state;
  }
};
