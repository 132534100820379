import { RootState } from ".";

export const loadState = (): RootState | undefined => {
  try {
    const serializedState = localStorage.getItem("state");

    if (serializedState === null) {
      return undefined;
    }

    const state = JSON.parse(serializedState);

    if (process.env.REACT_APP_VERSION) {
      if (state && state.options) {
        if (state.options.version === undefined) return undefined;
        if (state.options.version < process.env.REACT_APP_VERSION) return undefined;
      } 
    }

    return state;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);

    localStorage.setItem("state", serializedState);
  } catch (err) {
    console.log(err);
  }
};
