import { combineReducers } from 'redux';
import { charactersReducer } from './characters/reducers';
import { diceSetsReducer } from './diceSets/reducers';
import { historyReducer } from './history/reducers';
import { optionsReducer } from './options/reducers';
import { quickRollReducer } from './quickRoll/reducers';

export const rootReducer = combineReducers({
  options: optionsReducer,
  diceSets: diceSetsReducer,
  characters: charactersReducer,
  quickRoll: quickRollReducer,
  history: historyReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
