import {
  QuickRoll,
  QuickRollActionTypes,
  RESET_QUICK_ROLL,
  SET_QUICK_ROLL,
} from './types';

export function resetQuckRollAction(): QuickRollActionTypes {
  return {
    type: RESET_QUICK_ROLL,
  };
}

export function setQuickRollAction(quickRoll: QuickRoll): QuickRollActionTypes {
  return {
    type: SET_QUICK_ROLL,
    quickRoll,
  };
}
