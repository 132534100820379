import { Guid } from 'guid-typescript';
import { initDiceSets, newDiceSet } from '../common';
import {
  DiceSets,
  DiceSetsActionTypes,
  RESET_DICE_SETS,
  ADD_DICE_SET,
  UPDATE_DICE_SET,
  DELETE_DICE_SET,
  CLEAR_DICE_SETS,
  SELECT_DICE_SET,
  ADD_DICE,
  UPDATE_DICE,
  DELETE_DICE,
  DUPLICATE_DICE_SET,
} from './types';

const initialState: DiceSets = {
  selected: undefined,
  list: initDiceSets(),
};

export function diceSetsReducer(state = initialState, action: DiceSetsActionTypes): DiceSets {
  switch (action.type) {
    case RESET_DICE_SETS:
      return {
        selected: undefined,
        list: initDiceSets(), };

    case SELECT_DICE_SET:
      return {
        ...state,
        selected: action.diceSet,
      };

    case ADD_DICE_SET:
      return {
        ...state,
        list: [...state.list, newDiceSet(action.name)],
      };

    case DUPLICATE_DICE_SET:
      return {
        ...state,
        list: [...state.list, {...action.diceSet, id: Guid.raw(), name: `Duplicate ${action.diceSet.name}` }],
      };

    case UPDATE_DICE_SET:
      if (state.list.find((item) => item.id === action.diceSet.id)) {
        return {
          ...state,
          list: state.list.map((item) => (item.id === action.diceSet.id ? action.diceSet : item)),
        };
      };

      return state;

    case DELETE_DICE_SET:
      return {
        ...state,
        list: state.list.filter((item) => item.id !== action.id),
      };

    case CLEAR_DICE_SETS:
      return {
        ...state,
        list: [],
      };

    case ADD_DICE:
      return {
        ...state,
        list: state.list.map((diceSet) => diceSet.id === action.payload.diceSetId ? { ...diceSet, dice: [...diceSet.dice, { id: Guid.raw(), diceValue: action.payload.value }] } : diceSet),
      };

    case UPDATE_DICE:
      return {
        ...state,
        list: state.list.map((diceSet) => diceSet.id === action.payload.diceSetId ? { ...diceSet, dice: diceSet.dice.map((dice) => (dice.id === action.payload.dice.id ? action.payload.dice : dice))} : diceSet),
      };

    case DELETE_DICE:
      return {
        ...state,
        list: state.list.map((diceSet) => diceSet.id === action.payload.diceSetId ? { ...diceSet, dice: diceSet.dice.filter((dice) => dice.id !== action.payload.id) } : diceSet),
      };

    default:
      return state;
  }
};
