import React from 'react';
import { Card, CardContent, Typography, Box } from '@material-ui/core';
import { RollResult } from '../store/common';
import { DiceSetDisplay } from '.';
import { useStyles } from '../styles';
import RollResultDisplay from './RollResultDisplay';

interface ComponentProps {
  rollResult: RollResult
}

type Props = ComponentProps;

const HistoryCard: React.FunctionComponent<Props> = ({ rollResult }) => {
  const classes = useStyles();

  const { diceSet } = rollResult;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title} component="div" gutterBottom>
          <Box display="flex">
            <Box flexGrow={1}>
              {diceSet.name}
            </Box>
            {(diceSet.multiplier > 1) && (
              <Box marginLeft='16px'>
                Crit: {diceSet.critical < 20 ? `${diceSet.critical}-20` : '20'} x{diceSet.multiplier}
              </Box>
            )}
          </Box>
        </Typography>
        <DiceSetDisplay diceSet={diceSet} />
        <RollResultDisplay rollResult={rollResult} />
      </CardContent>
    </Card>
  )
}

export default HistoryCard;
