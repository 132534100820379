import React from 'react';
import { Card, CardContent, Typography, Grid, Divider } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../store';
import { useStyles } from '../styles';
import clsx from 'clsx';
import { Character } from '../store/common';
import { AttributeDisplay, HealthDisplay } from '.';
import { updateCharacterAction } from '../store/characters/actions';

const mapStateToProps = (state: RootState) => ({
  selected: state.characters.selected,
  characters: state.characters.list,
})

const mapDispatchToProps = {
  updateCharacter: (character: Character) => updateCharacterAction(character),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const SelectedCharacter: React.FunctionComponent<Props> = ({ selected, characters, updateCharacter }) => {
  const classes = useStyles();
  const [character, setCharacter] = React.useState<Character | undefined>(undefined);

  React.useEffect(() => {
    setCharacter(characters.find((item) => item.id === selected));
  }, [characters, selected]);

  if (!character) return <></>;

  return (
    <Card className={clsx(classes.card, classes.quickRoll)}>
      <CardContent>
        <Typography className={clsx(classes.title, classes.center)} gutterBottom>
          Selected Character - {character.name}
        </Typography>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography>{`BAB: ${character.baseAttack}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{`Init: ${character.initiative}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <HealthDisplay
              health={character.health}
              onChange={(value) => updateCharacter({ ...character, health: { ...character.health, current: value } })}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: `4px 0px` }}><Divider /></Grid>
          <Grid item xs={6}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <AttributeDisplay label="STR" value={character.attributes.STR} />
              </Grid>
              <Grid item xs={12}>
                <AttributeDisplay label="DEX" value={character.attributes.DEX} />
              </Grid>
              <Grid item xs={12}>
                <AttributeDisplay label="CON" value={character.attributes.CON} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <AttributeDisplay label="INT" value={character.attributes.INT} />
              </Grid>
              <Grid item xs={12}>
                <AttributeDisplay label="WIS" value={character.attributes.WIS} />
              </Grid>
              <Grid item xs={12}>
                <AttributeDisplay label="CHA" value={character.attributes.CHA} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default connector(SelectedCharacter);
