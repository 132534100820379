import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../store';
import { Character } from '../store/common';
import { selectCharacterAction, updateCharacterAction } from '../store/characters/actions';
import { useStyles } from '../styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid, Box, IconButton } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import { AttributeField } from '.';
import { isMobile } from 'react-device-detect';

interface ComponentProps {
  character: Character
  open: boolean
  setOpen: (value: boolean) => void,
}

const mapStateToProps = (state: RootState) => ({
  selected: state.characters.selected,
});

const mapDispatchToProps = {
  updateCharacter: (character: Character) => updateCharacterAction(character),
  selectCharacter: (id: string) => selectCharacterAction(id),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ComponentProps;

const EditCharacter: React.FunctionComponent<Props> = ({ character, open, setOpen, selected, updateCharacter, selectCharacter }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullScreen={isMobile}>
      <DialogTitle>
        <Box display="flex">
          <Box flexGrow={1}>
            Edit Character
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label="Name"
              value={character.name}
              onChange={(event) => updateCharacter({ ...character, name: event.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <TextField
                  fullWidth
                  required
                  label="Current HP"
                  value={character.health.current}
                  type='number'
                  onChange={(event) => updateCharacter({ ...character, health: { ...character.health, current: Number(event.target.value) } })}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  fullWidth
                  required
                  label="Max HP"
                  value={character.health.max}
                  type='number'
                  onChange={(event) => updateCharacter({ ...character, health: { ...character.health, max: Number(event.target.value) } })}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  fullWidth
                  required
                  label="Temp HP"
                  value={character.health.temporary}
                  type='number'
                  onChange={(event) => updateCharacter({ ...character, health: { ...character.health, temporary: Number(event.target.value) } })}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <TextField
                  fullWidth
                  required
                  label="Initiative"
                  value={character.initiative}
                  type='number'
                  onChange={(event) => updateCharacter({ ...character, initiative: Number(event.target.value) })}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  fullWidth
                  required
                  label="Base Attack"
                  value={character.baseAttack}
                  type='number'
                  onChange={(event) => updateCharacter({ ...character, baseAttack: Number(event.target.value) })}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AttributeField
                  label="Strength"
                  value={character.attributes.STR}
                  onChange={(value: number) => updateCharacter({ ...character, attributes: { ...character.attributes, STR: value } })}
                />
              </Grid>
              <Grid item xs={12}>
                <AttributeField
                  label="Dexterity"
                  value={character.attributes.DEX}
                  onChange={(value: number) => updateCharacter({ ...character, attributes: { ...character.attributes, DEX: value } })}
                />
              </Grid>
              <Grid item xs={12}>
                <AttributeField
                  label="Constitution"
                  value={character.attributes.CON}
                  onChange={(value: number) => updateCharacter({ ...character, attributes: { ...character.attributes, CON: value } })}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AttributeField
                  label="Intelligence"
                  value={character.attributes.INT}
                  onChange={(value: number) => updateCharacter({ ...character, attributes: { ...character.attributes, INT: value } })}
                />
              </Grid>
              <Grid item xs={12}>
                <AttributeField
                  label="Wisdom"
                  value={character.attributes.WIS}
                  onChange={(value: number) => updateCharacter({ ...character, attributes: { ...character.attributes, WIS: value } })}
                />
              </Grid>
              <Grid item xs={12}>
                <AttributeField
                  label="Charisma"
                  value={character.attributes.CHA}
                  onChange={(value: number) => updateCharacter({ ...character, attributes: { ...character.attributes, CHA: value } })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => selectCharacter(character.id)} color="default">
          <Check color={character.id === selected ? "secondary" : "inherit"} className={classes.marginRight} />
          Selected
        </Button>
        <Button onClick={() => setOpen(false)} color="secondary">Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default connector(EditCharacter);
