export const RESET_QUICK_ROLL = 'RESET_QUICK_ROLL';
export const SET_QUICK_ROLL = 'SET_QUICK_ROLL';

export interface QuickRoll {
  count: number,
  die: number,
  modifier: number,
  attribute: string,
};

interface ResetQuickRollAction {
  type: typeof RESET_QUICK_ROLL
}

interface SetQuicRollAction {
  type: typeof SET_QUICK_ROLL
  quickRoll: QuickRoll
}

export type QuickRollActionTypes = 
  ResetQuickRollAction |
  SetQuicRollAction;
