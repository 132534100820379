import { Guid } from "guid-typescript";
import { Options } from "../options/types";
import { Attribute, Attributes, AttributeType, Character, DamageType, Dice, DiceSet, DiceType, Die, RollResult } from "./types";

export const initDice = (): Die[] => [
  { value: 2, label: 'D2', color: '#BB86FC' },
  { value: 3, label: 'D3', color: '#F2AA4C' },
  { value: 4, label: 'D4', color: '#03DAC6' },
  { value: 6, label: 'D6', color: '#CF6679' },
  { value: 8, label: 'D8', color: '#BB86FC' },
  { value: 10, label: 'D10', color: '#F2AA4C' },
  { value: 12, label: 'D12', color: '#03DAC6' },
  { value: 20, label: 'D20', color: '#CF6679' },
  { value: 100, label: 'D100', color: '#BB86FC' },
];

export const initAttributes = (): Attribute[] => [
  { value: '', label: 'None' },
  { value: 'BAB', label: 'Base Attack' },
  { value: 'STR', label: 'Strength' },
  { value: 'DEX', label: 'Dexterity' },
  { value: 'CON', label: 'Consitution' },
  { value: 'INT', label: 'Intelligence' },
  { value: 'WIS', label: 'Wisdom' },
  { value: 'CHA', label: 'Charisma' },
];

export const initDamageType = (): DamageType[] => [
  { value: '', label: 'None', group: 'Physical' },
  { value: 'PHYSICAL', label: 'Physical', group: 'Physical' },
  { value: 'BLUDGEONING', label: 'Blunt', group: 'Physical' },
  { value: 'PIERCING', label: 'Piercing', group: 'Physical' },
  { value: 'SLASHING', label: 'Slashing', group: 'Physical' },
  { value: 'ACID', label: 'Acid', group: 'Elemental' },
  { value: 'COLD', label: 'Cold', group: 'Elemental'  },
  { value: 'ELECTRICITY', label: 'Electricity', group: 'Elemental' },
  { value: 'FIRE', label: 'Fire', group: 'Elemental' },
  { value: 'SONIC', label: 'Sonic', group: 'Elemental' },
  { value: 'POSITIVE', label: 'Positive', group: 'Positive/Negative' },
  { value: 'NEGATIVE', label: 'Negative', group: 'Positive/Negative' },
  { value: 'FORCE', label: 'Force', group: 'Force' },
  { value: 'GOOD', label: 'Good', group: 'Alignment' },
  { value: 'NEUTRAL', label:'Neutal', group: 'Alignment' },
  { value: 'EVIL', label: 'Evil', group: 'Alignment' },
  { value: 'LAW', label: 'Law', group: 'Alignment' },
  { value: 'CHAOS', label: 'Chaos', group: 'Alignment' },
  { value: 'POISON', label: 'Poison', group: 'Poison' },
  { value: 'MENTAL', label: 'Mental', group: 'Mental' },
  { value: 'BLEED', label: 'Bleed', group: 'Bleed' },
  { value: 'NONLEATHAL', label: 'Nonleathal', group: 'Nonlethal' },
  { value: 'PRECISION', label: 'Precision', group: 'Precision'},
];

export const newCharacter = (name: string): Character => ({
  id: Guid.raw(),
  name,
  health: {
    max: 10,
    current: 10,
    temporary: 0,
  },
  baseAttack: 0, 
  initiative: 0,
  attributes: {
    STR: 10,
    DEX: 10,
    CON: 10,
    INT: 10,
    WIS: 10,
    CHA: 10
  },
  savingThrows: {
    FORT: 0,
    REF: 0,
    WILL: 0,
  },
});

export const initCharacters = (): Character[] => [ newCharacter('My Character') ];

export const newDiceSet = (name: string): DiceSet => ({
  id: Guid.raw(),
  name,
  critical: 20,
  multiplier: 2,
  dice: [],
});

export const initDiceSets = (): DiceSet[] => [
  {
    id: Guid.raw(),
    name: 'Initiative',
    critical: 20,
    multiplier: 1,
    dice: [
      {
        id: Guid.raw(),
        diceValue: {
          type: DiceType.roll,
          count: 1,
          die: 20,
        },
      },
      {
        id: Guid.raw(),
        diceValue: {
          type: DiceType.attribute,
          attribute: AttributeType.DEX,
        },
      },
    ],
  },
  {
    id: Guid.raw(),
    name: 'Melee Attack',
    critical: 20,
    multiplier: 1,
    dice: [
      {
        id: Guid.raw(),
        diceValue: {
          type: DiceType.roll,
          count: 1,
          die: 20,
        },
      },
      {
        id: Guid.raw(),
        diceValue: {
          type: DiceType.baseAttack,
        },
      },
      {
        id: Guid.raw(),
        diceValue: {
          type: DiceType.modifier,
          modifier: 0,
        },
      },
      {
        id: Guid.raw(),
        diceValue: {
          type: DiceType.attribute,
          attribute: AttributeType.STR,
        },
      },
    ],
  },
  {
    id: Guid.raw(),
    name: 'Ranged Attack',
    critical: 20,
    multiplier: 1,
    dice: [
      {
        id: Guid.raw(),
        diceValue: {
          type: DiceType.roll,
          count: 1,
          die: 20,
        },
      },
      {
        id: Guid.raw(),
        diceValue: {
          type: DiceType.baseAttack,
        },
      },
      {
        id: Guid.raw(),
        diceValue: {
          type: DiceType.modifier,
          modifier: 0,
        },
      },
      {
        id: Guid.raw(),
        diceValue: {
          type: DiceType.attribute,
          attribute: AttributeType.DEX,
        },
      }
    ],
  },
];

export const newRollResult = (diceSet: DiceSet, character?: Character): RollResult => ({
  id: Guid.raw(),
  timestamp: new Date(),
  diceSet,
  vulnerabilities: [],
  diceResults: [],
});

export const modifier = (attribute: number): number => {
  return Math.floor((attribute - 10) / 2);
}

export const attributesToString = (attributes: Attributes): string => {
  const values: Array<string> = [];

  values.push(`STR: ${attributes.STR}(${modifier(attributes.STR)})`);
  values.push(`DEX: ${attributes.DEX}(${modifier(attributes.DEX)})`);
  values.push(`CON: ${attributes.CON}(${modifier(attributes.CON)})`);
  values.push(`INT: ${attributes.INT}(${modifier(attributes.INT)})`);
  values.push(`WIS: ${attributes.WIS}(${modifier(attributes.WIS)})`);
  values.push(`CHA: ${attributes.CHA}(${modifier(attributes.CHA)})`);

  return values.join(', ');
};

export const diceToString = (dice: Dice[], options: Options): string => {
  if (dice.length === 0) return 'No dice';
  return dice.map((item) => {
    if (item.diceValue.type === DiceType.modifier) {
      return item.diceValue.modifier.toString();
    }

    if (item.diceValue.type === DiceType.baseAttack) {
      return 'BAB'
    }

    if (item.diceValue.type === DiceType.attribute) {
      return item.diceValue.attribute;
    }

    if (item.diceValue.type === DiceType.roll) {
      const { count, die } = item.diceValue;

      const { label, color } = options.dice.find((item) => item.value === die) || options.dice[0];

      return `${count}<span style='color:${color};margin-left:2px;'>${label}</span>`;
    }

    return '';
  }).join(' + ')
};

export const randomInt = (max: number): number => {
  return Math.floor(Math.random() * Math.floor(max)) + 1;
};

export const rollDice = (diceSet: DiceSet, options: Options, character?: Character): RollResult => {
  const rollResult: RollResult = newRollResult(diceSet, character);

  diceSet.dice.forEach((item) => {
    if (item.diceValue.type === DiceType.modifier) {
      rollResult.diceResults.push({ ...item, value: item.diceValue.modifier });
    } 
    
    if (character) {
      if (item.diceValue.type === DiceType.baseAttack) {
        rollResult.diceResults.push({ ...item, value: character.baseAttack, label: 'BAB' });
      }

      if (item.diceValue.type === DiceType.attribute) {
        const { attribute } = item.diceValue;
        const mod = modifier(Object.entries(character.attributes).find(([key, value]) => key === attribute)![1]);

        rollResult.diceResults.push({ ...item, value: mod, label: attribute });
      }
    }

    if (item.diceValue.type === DiceType.roll) {
      const { count, die } = item.diceValue;

      for (let x = 0; x < count; x++) {
        let value = randomInt(die);

        if (options.fickleAttack !== 0) {
          if (value <= options.fickleAttack) value = die;
        }

        const { color }: Die = options.dice.find((value) => value.value === die) || options.dice[0];

        rollResult.diceResults.push({ ...item, value, color });
      }
    }
  });

  return rollResult;
}
