import { Dice, DiceSet, DiceValue } from '../common';
import {
  DiceSetsActionTypes,
  RESET_DICE_SETS,
  ADD_DICE_SET,
  UPDATE_DICE_SET,
  DELETE_DICE_SET,
  CLEAR_DICE_SETS,
  ADD_DICE,
  UPDATE_DICE,
  DELETE_DICE,
  DUPLICATE_DICE_SET,
} from './types';

export function resetDiceSetsAction(): DiceSetsActionTypes {
  return {
    type: RESET_DICE_SETS,
  };
}

export function addDiceSetAction( name: string ): DiceSetsActionTypes {
  return {
    type: ADD_DICE_SET,
    name,
  };
}

export function updateDiceSetAction( diceSet: DiceSet ): DiceSetsActionTypes {
  return {
    type: UPDATE_DICE_SET,
    diceSet,
  };
}

export function deleteDiceSetAction( id: string ): DiceSetsActionTypes {
  return {
    type: DELETE_DICE_SET,
    id,
  };
}

export function clearDiceSetsAction(): DiceSetsActionTypes {
  return {
    type: CLEAR_DICE_SETS,
  };
}

export function addDiceAction( diceSetId: string, value: DiceValue ): DiceSetsActionTypes {
  return {
    type: ADD_DICE,
    payload: {
      diceSetId,
      value,
    },
  };
}

export function duplicateDiceSetAction( diceSet: DiceSet ): DiceSetsActionTypes {
  return {
    type: DUPLICATE_DICE_SET,
    diceSet
  }
}

export function updateDiceAction( diceSetId: string, dice: Dice ): DiceSetsActionTypes {
  return {
    type: UPDATE_DICE,
    payload: {
      diceSetId,
      dice,
    },
  };
}

export function deleteDiceAction( diceSetId: string, id: string ): DiceSetsActionTypes {
  return {
    type: DELETE_DICE,
    payload: {
      diceSetId,
      id,
    },
  };
}
