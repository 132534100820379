import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Typography } from '@material-ui/core';
import { DiceSet, diceToString } from '../store/common';
import { RootState } from '../store';

interface ComponentProps {
  diceSet: DiceSet
}
const mapStateToProps = (state: RootState) => ({
  options: state.options,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ComponentProps;

const DiceSetDisplay: React.FunctionComponent<Props> = ({ diceSet, options }) => (
  <Typography variant="h5" component="h2">
    <span dangerouslySetInnerHTML={{ __html: diceToString(diceSet.dice, options) }} />
  </Typography>
);

export default connector(DiceSetDisplay);
