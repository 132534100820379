import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';

interface ComponentProps {
  value?: number
  onChange?: (value: number) => void
}

type Props = ComponentProps;

const CriticalSelect: React.FunctionComponent<Props> = ({ value, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(event.target.value);

    if (value > 20) value = 20;
    if (value < 1) value = 1;

    if (onChange) onChange(value);
  }

  return (
    <TextField
      fullWidth
      required
      type="number"
      label="Critical"
      value={value}
      InputProps={{
        endAdornment: <InputAdornment position="end"> -20 </InputAdornment>
      }}
      onChange={handleChange}
    />
  );
};

export default CriticalSelect;
