import React from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, IconButton, Typography, Grid } from '@material-ui/core';
import { Add, Close, Remove } from '@material-ui/icons';

interface Props {
  open: boolean
  setOpen: (isOpen: boolean) => void
  onConfirm: (value: number) => void
}

const AttributeField: React.FunctionComponent<Props> = ({ open, setOpen, onConfirm }) => {
  const [value, setValue] = React.useState<number>(0);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box display="flex">
          <Box flexGrow={1}>
            Edit Health
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <Typography>
              Enter the amount of damage or healing and then click Harm or Heal.
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              type="number"
              label='HP'
              value={value}
              onChange={(event) => setValue(Number(event.target.value))}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => { setOpen(false); onConfirm(value * -1); }}><Remove /> Harm</Button>
        <Button onClick={() => { setOpen(false); onConfirm(value); }}><Add /> Heal</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttributeField;
