import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { modifier } from '../store/common';

interface ComponentProps {
  label: string
  value: number
  component?: string
}

type Props = TypographyProps & ComponentProps;

const AttributeDisplay: React.FunctionComponent<Props> = ({ label, value, ...others }) => (
  <Typography {...others}>
    {`${label}: ${value} (${modifier(value)})`}
  </Typography>
)

export default AttributeDisplay;
