import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, IconButton, Box, Tooltip } from '@material-ui/core';
import { RootState } from '../store';
import { AttributeType, Dice, DiceSet, DiceType, DiceValue, Multiplier } from '../store/common';
import { addDiceAction, deleteDiceAction, updateDiceAction, updateDiceSetAction } from '../store/diceSets/actions';
import { Casino, Close, Delete, ExposurePlus1, SportsMma, Toc } from '@material-ui/icons';
import { EditDiceValue, DamageTypeSelect, CriticalSelect, MultiplierSelect } from '.';
import { isMobile } from 'react-device-detect';

interface ComponentProps {
  diceSet: DiceSet
  open: boolean
  setOpen: (value: boolean) => void,
}

const mapStateToProps = (state: RootState) => ({
});

const mapDispatchToProps = {
  updateDiceSet: (diceSet: DiceSet) => updateDiceSetAction(diceSet),
  addDice: (diceSetId: string, value: DiceValue) => addDiceAction(diceSetId, value),
  updateDice: (diceSetId: string, dice: Dice) => updateDiceAction(diceSetId, dice),
  deleteDice: (diceSetId: string, id: string) => deleteDiceAction(diceSetId, id),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ComponentProps;

const EditDiceSet: React.FunctionComponent<Props> = ({ diceSet, open, setOpen, updateDiceSet, addDice, updateDice, deleteDice, }) => (
  <Dialog open={open} onClose={() => setOpen(false)} fullScreen={isMobile}>
    <DialogTitle>
      <Box display="flex">
        <Box flexGrow={1}>
          Edit Dice Set            
        </Box>
        <Box>
          <IconButton size="small" onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Box>
      </Box>
    </DialogTitle>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            required
            label="Name"
            value={diceSet.name}
            onChange={(event) => updateDiceSet({ ...diceSet, name: event.target.value })}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <CriticalSelect value={diceSet.critical} onChange={(value) => updateDiceSet({ ...diceSet, critical: value })} />
        </Grid>
        <Grid item xs={6} md={2}>
          <MultiplierSelect value={diceSet.multiplier} onChange={(value: number) => updateDiceSet({ ...diceSet, multiplier: value as Multiplier })} />
        </Grid>
        {diceSet.dice.map((item) => (
          <Grid item xs={12} key={item.id}>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item>
                <DamageTypeSelect value={item.name} onChange={(value: string) => updateDice(diceSet.id, { ...item, name: value })} />
              </Grid>
              <EditDiceValue diceValue={item.diceValue} onChange={(diceValue: DiceValue) => updateDice(diceSet.id, { ...item, diceValue })} />
              <Grid item>
                <IconButton size="small" color="secondary" onClick={() => deleteDice(diceSet.id, item.id)}><Delete /></IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </DialogContent>
    <DialogActions>
      <IconButton onClick={() => addDice(diceSet.id, { type: DiceType.roll, count: 1, die: 6 })}>
        <Tooltip title="Add Dice" arrow>
          <Casino />
        </Tooltip>
      </IconButton>
      <IconButton onClick={() => addDice(diceSet.id, { type: DiceType.attribute, attribute: AttributeType.STR })}>
        <Tooltip title="Add Attribute" arrow>
          <Toc />
        </Tooltip>
      </IconButton>
      <IconButton onClick={() => addDice(diceSet.id, { type: DiceType.modifier, modifier: 0 })}>
        <Tooltip title="Add Modifier" arrow>
          <ExposurePlus1 />
        </Tooltip>
      </IconButton>
      <IconButton onClick={() => addDice(diceSet.id, { type: DiceType.baseAttack })}>
        <Tooltip title="Add Base Attack" arrow>
          <SportsMma />
        </Tooltip>
      </IconButton>
      <Button onClick={() => setOpen(false)} color="secondary">Close</Button>
    </DialogActions>
  </Dialog>
);

export default connector(EditDiceSet);
