import { initAttributes, initDamageType, initDice } from '../common';
import {
  Options,
  OptionsActionTypes,
  RESET_OPTIONS,
  SET_ALLOW_NEGATIVE_ROLLS,
  SET_FICKLE_ATTACK,
} from './types';

const initialState: Options = {
  version: process.env.REACT_APP_VERSION,
  dice: initDice(),
  attributes: initAttributes(),
  damageTypes: initDamageType(),
  allowNegativeRolls: false,
  fickleAttack: 0,
};

export function optionsReducer(state = initialState, action: OptionsActionTypes): Options {
  switch (action.type) {
    case RESET_OPTIONS:
      return initialState;

    case SET_ALLOW_NEGATIVE_ROLLS:
      return {
        ...state, 
        allowNegativeRolls: action.value,
      };

    case SET_FICKLE_ATTACK:
      return {
        ...state,
        fickleAttack: action.value,
      };

    default:
      return state;
  }
};
