import { Dice, DiceSet, DiceValue } from '../common';

export const RESET_DICE_SETS = 'RESET_DICE_SETS';
export const SELECT_DICE_SET = 'SELECT_DICE_SET';
export const ADD_DICE_SET = 'ADD_DICE_SET';
export const DUPLICATE_DICE_SET = 'DUPLICATE_DICE_SET';
export const UPDATE_DICE_SET = 'UPDATE_DICE_SET';
export const DELETE_DICE_SET = 'DELETE_DICE_SET';
export const CLEAR_DICE_SETS = 'CLEAR_DICE_SETS';
export const ADD_DICE = 'ADD_DICE';
export const UPDATE_DICE = 'UPDATE_DICE';
export const DELETE_DICE = 'DELETE_DICE';

export interface DiceSets {
  selected?: DiceSet
  list: DiceSet[]
};

interface ResetDiceSetsAction {
  type: typeof RESET_DICE_SETS
}

interface SelectDiceSetAction {
  type: typeof SELECT_DICE_SET
  diceSet: DiceSet
}

interface AddDiceSetAction {
  type: typeof ADD_DICE_SET
  name: string
}

interface DuplicationDiceSetAction {
  type: typeof DUPLICATE_DICE_SET
  diceSet: DiceSet
}

interface UpdateDiceSetAction {
  type: typeof UPDATE_DICE_SET
  diceSet: DiceSet
}

interface DeleteDiceSetAction {
  type: typeof DELETE_DICE_SET
  id: string
}

interface ClearDiceSetsAction {
  type: typeof CLEAR_DICE_SETS
}

interface AddDiceAction {
  type: typeof ADD_DICE
  payload: {
    diceSetId: string
    value: DiceValue
  }
}

interface UpdateDiceAction {
  type: typeof UPDATE_DICE
  payload: {
    diceSetId: string
    dice: Dice
  },
}

interface DeleteDiceAction {
  type: typeof DELETE_DICE
  payload: {
    diceSetId: string
    id: string
  }
}

export type DiceSetsActionTypes = 
  ResetDiceSetsAction |
  SelectDiceSetAction |
  AddDiceSetAction |
  DuplicationDiceSetAction |
  UpdateDiceSetAction |
  DeleteDiceSetAction |
  ClearDiceSetsAction |
  AddDiceAction |
  UpdateDiceAction |
  DeleteDiceAction;
