import { initCharacters, newCharacter } from '../common';
import {
  Characters,
  CharactersActionTypes,
  RESET_CHARACTERS,
  ADD_CHARACTER,
  UPDATE_CHARACTER,
  DELETE_CHARACTER,
  CLEAR_CHARACTERS,
  SELECT_CHARACTER,
} from './types';

const initialState: Characters = {
  selected: undefined,
  list: initCharacters(),
};

export function charactersReducer(state = initialState, action: CharactersActionTypes): Characters {
  switch (action.type) {
    case RESET_CHARACTERS:
      return {
        selected: undefined,
        list: initCharacters(), };

    case SELECT_CHARACTER:
      return {
        ...state,
        selected: state.selected === action.id ? undefined : action.id,
      };

    case ADD_CHARACTER:
      return {
        ...state,
        list: [...state.list, newCharacter('New Character')],
      };

    case UPDATE_CHARACTER:
      if (state.list.find((item) => item.id === action.character.id)) {
        return {
          ...state,
          list: state.list.map((item) => (item.id === action.character.id ? action.character : item)),
        };
      };

      return state;

    case DELETE_CHARACTER:
      return {
        ...state,
        list: state.list.filter((item) => item.id !== action.id),
      };

    case CLEAR_CHARACTERS:
      return {
        ...state,
        list: [],
      };

    default:
      return state;
  }
};
