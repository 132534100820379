import {
  QuickRoll,
  QuickRollActionTypes,
  RESET_QUICK_ROLL,
  SET_QUICK_ROLL,
} from './types';

const initialState: QuickRoll = {
  count: 1,
  die: 6,
  modifier: 0,
  attribute: '',
};

export function quickRollReducer(state = initialState, action: QuickRollActionTypes): QuickRoll {
  switch (action.type) {
    case RESET_QUICK_ROLL:
      return initialState;

    case SET_QUICK_ROLL:
      return {
        ...action.quickRoll,
      };

    default:
      return state;
  }
};
