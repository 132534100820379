import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { RootState } from '../store';
import { connect, ConnectedProps } from 'react-redux';

interface ComponentProps {
  value?: number
  onChange?: (value: number) => void
}

const mapStateToProps = (state: RootState) => ({
  dice: state.options.dice,
})

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ComponentProps;

const AttributeSelect: React.FunctionComponent<Props> = ({ dice, value, onChange }) => (
  <TextField
    fullWidth
    select
    label="Die"
    value={value}
    onChange={(event) => onChange && onChange(Number(event.target.value))}
    style={{ minWidth: 64 }}
  >
    {dice.map((item) => (
      <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
    ))}
  </TextField>
);

export default connector(AttributeSelect);
