import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

interface Props {
  title: string
  open: boolean
  setOpen: (isOpen: boolean) => void
  onConfirm: () => void
}

const ConfirmDialog: React.FunctionComponent<Props> = ({ title, open, setOpen, onConfirm, children }) => (
  <Dialog open={open} onClose={() => setOpen(false)}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <Button onClick={() => setOpen(false)} color="secondary">Cancel</Button>
      <Button onClick={() => {setOpen(false); onConfirm();}}>Ok</Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
