import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';

interface ComponentProps {
  value?: number
  onChange?: (value: number) => void
}

type Props = ComponentProps;

const MultiplierSelect: React.FunctionComponent<Props> = ({ value, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(event.target.value);

    if (value > 20) value = 20;
    if (value < 1) value = 1;

    if (onChange) onChange(value);
  }

  return (
    <TextField
      fullWidth
      required
      select
      type="number"
      label="Multiplier"
      value={value}
      onChange={handleChange}
    >
      <MenuItem value={1}>No critical</MenuItem>
      <MenuItem value={2}>x2</MenuItem>
      <MenuItem value={3}>x3</MenuItem>
      <MenuItem value={4}>x4</MenuItem>
    </TextField>
  );
};

export default MultiplierSelect;
