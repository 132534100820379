import React from 'react';
import { AppBar, IconButton, Toolbar, Typography, Tooltip, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Menu as MenuIcon, Casino as CasinoIcon, AccountCircle as AccountCircleIcon } from '@material-ui/icons';
import { addDiceSetAction } from '../store/diceSets/actions';
import { RootState } from '../store';
import { connect, ConnectedProps } from 'react-redux';
import { addCharacterAction } from '../store/characters/actions';
import { useStyles } from '../styles';

const mapStateToProps = (state: RootState) => ({
});

const mapDispatchToProps = {
  addDiceSet: () => addDiceSetAction('New Dice Set'),
  addCharacter: () => addCharacterAction('New Character'),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const Header: React.FunctionComponent<Props> = ({ addDiceSet, addCharacter }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  const isMenuOpen = Boolean(anchorEl);

  return (
    <>
      <AppBar position="fixed" color="default" >
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            edge="start"
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>DnDice</Typography>
          <Typography variant="caption" noWrap className={classes.version}>v{process.env.REACT_APP_VERSION}</Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton onClick={() => addDiceSet()}>
              <Tooltip title="Add Dice Set">
                <CasinoIcon />
              </Tooltip>
            </IconButton>
            <IconButton edge="end" onClick={() => addCharacter()}>
              <Tooltip title="Add Character">
                <AccountCircleIcon />
              </Tooltip>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => { addDiceSet(); handleMenuClose(); }}>
          <ListItemIcon><CasinoIcon /></ListItemIcon>
          <ListItemText primary="Add new Dice Set" />
        </MenuItem>
        <MenuItem onClick={() => { addCharacter(); handleMenuClose(); }}>
          <ListItemIcon><AccountCircleIcon /></ListItemIcon>
          <ListItemText primary="Add new Character" />
        </MenuItem>
      </Menu>
    </>
  )
}

export default connector(Header);
