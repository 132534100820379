import React from 'react';
import { Typography, Box, CircularProgress, IconButton } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { useStyles } from '../styles';
import clsx from 'clsx';
import { HealthEdit } from '.';
import { Health } from '../store/common';

interface ComponentProps {
  health: Health
  onChange?: (value: number) => void
}

type Props = ComponentProps;

const HealthDisplay: React.FunctionComponent<Props> = ({ health, onChange }) => {
  const [healthOpen, setHealthOpen] = React.useState<boolean>(false);
  const classes = useStyles();

  const max = health.max + health.temporary;
  const { current } = health;

  const getColor = (percent: number): string => {
    if (percent > 100) return 'lime';
    if (percent > 66) return 'green';
    if (percent > 33) return 'yellow';
    if (percent > 0) return 'orange';
    return 'red';
  };

  const percent = Math.round((current / max) * 100);

  const handleHealthEdit = (value: number) => {
    if (onChange) {
      let hp = current + value;
      if (hp > max) hp = max;

      onChange(hp);
    }
  }

  return (
    <>
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" value={percent} style={{ height: 44, width: 44, color: getColor(percent)}} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {onChange && (
            <IconButton
              size="small"
              className={clsx(classes.marginRight, classes.error)}
              onClick={() => onChange && onChange(current - 1)}
            >
              <Remove />
            </IconButton>
          )}
          <Typography className={classes.pointer} variant="caption" component="div" onClick={() => setHealthOpen(Boolean(onChange))}>{`${current}/${max}`}</Typography>
          {onChange && (
            <IconButton
              size="small"
              className={clsx(classes.marginLeft, classes.success)}
              onClick={() => onChange && (current < max) && onChange(current + 1)}
            >
              <Add />
            </IconButton>
          )}
        </Box>
      </Box>
      <HealthEdit open={healthOpen} setOpen={setHealthOpen} onConfirm={handleHealthEdit} />
    </>
  );
};

export default HealthDisplay;
