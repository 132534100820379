import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Card, CardActions, CardContent, IconButton, Tooltip, Typography, Grid, Divider } from '@material-ui/core';
import { Check, Delete, Edit } from '@material-ui/icons';
import { Character } from "../store/common";
import { RootState } from '../store';
import { deleteCharacterAction, selectCharacterAction, updateCharacterAction } from '../store/characters/actions';
import { AttributeDisplay, ConfirmDialog, EditCharacter } from '.';
import { useStyles } from '../styles';
import HealthDisplay from './HealthDisplay';

interface ComponentProps {
  character: Character
}

const mapStateToProps = (state: RootState) => ({
  selected: state.characters.selected,
});

const mapDispatchToProps = {
  updateCharacter: (character: Character) => updateCharacterAction(character),
  deleteCharacter: (id: string) => deleteCharacterAction(id),
  selectCharacter: (id: string) => selectCharacterAction(id),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ComponentProps;

const DiceSetCard: React.FunctionComponent<Props> = ({ character, selected, updateCharacter, deleteCharacter, selectCharacter }) => {
  const classes = useStyles();
  const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
  const [editOpen, setEditOpen] = React.useState<boolean>(false);

  const onEdit = () => {
    setEditOpen(true);
  };

  const onDelete = () => {
    deleteCharacter(character.id);
  };

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.title} gutterBottom>
            {character.name}
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography>{`BAB: ${character.baseAttack}`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{`Init: ${character.initiative}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <HealthDisplay health={character.health} />
            </Grid>
            <Grid item xs={12} style={{ padding: `8px 0px` }}><Divider /></Grid>
            <Grid item xs={6}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <AttributeDisplay label="STR" value={character.attributes.STR} />
                </Grid>
                <Grid item xs={12}>
                  <AttributeDisplay label="DEX" value={character.attributes.DEX} />
                </Grid>
                <Grid item xs={12}>
                  <AttributeDisplay label="CON" value={character.attributes.CON} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <AttributeDisplay label="INT" value={character.attributes.INT} />
                </Grid>
                <Grid item xs={12}>
                  <AttributeDisplay label="WIS" value={character.attributes.WIS} />
                </Grid>
                <Grid item xs={12}>
                  <AttributeDisplay label="CHA" value={character.attributes.CHA} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container justify="space-evenly">
            <Grid item>
              <Tooltip title="Select" arrow>
                <IconButton onClick={() => selectCharacter(character.id)} color={character.id === selected ? "secondary" : "inherit"}><Check /></IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Edit" arrow>
                <IconButton onClick={onEdit}><Edit /></IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Delete" arrow>
                <IconButton onClick={() => setConfirmOpen(true)}><Delete /></IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <ConfirmDialog title="Delete Character?" open={confirmOpen} setOpen={setConfirmOpen} onConfirm={onDelete}>
        <>Are you sure you want to delete <span className={classes.primary}>{character.name}</span>?</>
      </ConfirmDialog>
      <EditCharacter open={editOpen} character={character} setOpen={setEditOpen} />
    </>
  )
}

export default connector(DiceSetCard);
