import { Character } from '../common';

export const RESET_CHARACTERS = 'RESET_CHARACTERS';
export const SELECT_CHARACTER = 'SELECT_CHARACTER';
export const ADD_CHARACTER = 'ADD_CHARACTER';
export const UPDATE_CHARACTER = 'UPDATE_CHARACTER';
export const DELETE_CHARACTER = 'DELETE_CHARACTERT';
export const CLEAR_CHARACTERS = 'CLEAR_CHARACTERS';

export interface Characters {
  selected?: string
  list: Character[]
};

interface ResetCharactersAction {
  type: typeof RESET_CHARACTERS
}

interface SelectCharacterAction {
  type: typeof SELECT_CHARACTER
  id: string
}

interface AddCharacterAction {
  type: typeof ADD_CHARACTER
  name: string
}

interface UpdateCharacterAction {
  type: typeof UPDATE_CHARACTER
  character: Character
}

interface DeleteCharacterAction {
  type: typeof DELETE_CHARACTER
  id: string
}

interface ClearCharactersAction {
  type: typeof CLEAR_CHARACTERS
}

export type CharactersActionTypes = 
  ResetCharactersAction |
  SelectCharacterAction |
  AddCharacterAction |
  UpdateCharacterAction |
  DeleteCharacterAction |
  ClearCharactersAction;
